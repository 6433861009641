// Home.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import Services from './Services'; // Importar el componente de servicios
import WhatsAppButton from './WhatsAppButton';

const Home = () => {
  const [showLogo, setShowLogo] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const showLogoTimer = setTimeout(() => {
      setShowLogo(true);
    }, 3000); // 3 segundos para mostrar el logo

    return () => {
      clearTimeout(showLogoTimer);
    };
  }, []);

  const navigateToProducts = () => {
    navigate('/products');
  };

  const navigateToAbout = () => {
    navigate('/about');
  };

  return (
    <div className="home-container">
      <div className="home-logo">
        <img src="images/Logo.png" alt="Logo" className={`logo ${showLogo ? 'visible' : ''}`} />
        <img src="images/smoke.gif" alt="Smoke" className={`overlay-gif ${showLogo ? 'fade-out' : ''}`} />
      </div>
      <Services />
      <div className="products-section">
        <h2>Productos</h2>
        <div className="products">
          <img 
            src="images/products/Modelo 1.jpg" 
            alt="Producto 1" 
            className="product" 
            onClick={navigateToProducts} 
          />
          <img 
            src="images/products/Modelo 56.jpg" 
            alt="Producto 2" 
            className="product" 
            onClick={navigateToProducts} 
          />
          <img 
            src="images/products/Modelo 68.jpg" 
            alt="Producto 3" 
            className="product" 
            onClick={navigateToProducts} 
          />
        </div>
        <button className="view-all-button" onClick={navigateToProducts}>
          Ver todos los productos
        </button>
      </div>
      <div className="about-section">
      <h2>Para ESIPI S.A de C.V sería un gusto acompañarles en sus procesos de mejora
      continua y fortalecer a las empresas en la cultura de prevención y gestión empresarial.</h2>
        {/*<h2>Somos una empresa responsable avalada por...</h2>
        <button onClick={navigateToAbout}>Saber más</button>
        <img src="images/About.jpg" alt="About Us" className="about-image" />*/}
      </div>
      <WhatsAppButton />
    </div>
  );
};

export default Home;
