import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Importa useLocation
import emailjs from 'emailjs-com';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import './styles.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import WhatsAppButton from './WhatsAppButton';

const Contact = () => {
  const location = useLocation(); // Usa useLocation para acceder al estado
  const prefilledMessage = location.state?.prefilledMessage || '';

  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    description: prefilledMessage, // Prellenar la descripción
  });

  const [locationData, setLocationData] = useState({
    address: 'Calle Falsa 123, Ciudad, País',
    email: 'default@example.com',
    center: {
      lat: 19.834948144842876,
      lng: -99.07936664585871
    }
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const pueblaCoords = { lat: 18.9687716, lng: -98.2240830 };
          const distanceThreshold = 0.5;
          const isInPuebla = Math.abs(latitude - pueblaCoords.lat) < distanceThreshold && 
                             Math.abs(longitude - pueblaCoords.lng) < distanceThreshold;
          if (isInPuebla) {
            setLocationData({
              address: '',
              email: '',
              center: pueblaCoords
            });
          }
        },
        (error) => {
          console.error("Error al obtener la ubicación: ", error);
        }
      );
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send('service_g0ffztc', 'template_ps1gokv', formData, 't9gJvGnR3ksNqp4AI')
      .then((response) => {
        console.log('Success:', response);
        alert('Message sent successfully!');
      }, (err) => {
        console.log('Failed:', err);
        alert('Failed to send message.');
      });

    setFormData({
      name: '',
      company: '',
      email: '',
      phone: '',
      description: ''
    });
  };

  return (
    <div className="container mt-5">
      <h1>Contáctanos</h1>
      <p>Formulario de Contacto</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Nombre:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="company">Nombre de la Empresa:</label>
          <input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Teléfono:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Descripción:</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="form-control"
            rows="4"
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Enviar</button>
      </form>

      <div className="location-info mt-4">
      </div>

      <div className="map-container mt-5">
        <LoadScript googleMapsApiKey="AIzaSyDtGg1WRYG9crlQLDGTG6ftO5zwLHgRHJc">
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '60vh' }}
            center={locationData.center}
            zoom={15}
          >
            <Marker position={locationData.center} />
          </GoogleMap>
        </LoadScript>
      </div>

      <WhatsAppButton /> 
    </div>
  );
};

export default Contact;
