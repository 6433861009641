import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import './Products.css';
import WhatsAppButton from './WhatsAppButton';

const Products = () => {
  
  const [hoveredProduct, setHoveredProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSection, setSelectedSection] = useState('Todos');
  const sectionMenuRef = useRef(null);
  const [legendHidden, setLegendHidden] = useState(false);
  const navigate = useNavigate(); // Usa useNavigate para redirigir
  

  const products = [
    { 
      id: 1, 
      name: 'Catálogo de refacciones', 
      section: 'Refacciones',
      image: 'images/products/CatalogoRefacciones.jpeg', 
      description: 'CATALOGO REFACCIONES', 
      pdf: 'images/products/Catalogo refacciones .pdf'
    },
  { 
      id: 2, 
      name: 'Catálogo de equipo de protección personal', 
      section: 'Equipo de protección personal',
      image: 'images/products/CatalogoProteccion.jpeg', 
      description: 'CATALOGO EQUIPO DE PROTECCION PERSONAL', 
      pdf: 'images/products/Catalogo Equipo de proteccion personal .pdf'
    },
  { 
      id: 3, 
      name: 'Catálogo de polvo normado no certificado', 
      section: 'Venta de polvo normado no certificado',
      image: 'images/products/CatalogoPolvo.jpeg', 
      description: 'CATALOGO POLVO NORMADO NO CERTIFICADO', 
      pdf: 'images/products/Catalogo PQS.pdf'
    },

  { 
      id: 4, 
      name: 'Catálogo de señalamientos', 
      section: 'Señalamientos',
      image: 'images/products/CatalogoSeñalamientos.jpeg', 
      description: 'CATALOGO SENALAMIENTOS', 
      pdf: 'images/products/Catalogo Señalamientos .pdf'
    },
  { 
      id: 5, 
      name: 'Catálogo de equipo de seguridad', 
      section: 'Equipo de seguridad',
      image: 'images/products/CatalogoSeguridad.jpeg', 
      description: 'CATALOGO EQUIPO DE SEGURIDAD', 
      pdf: 'images/products/Catalogo Equipo de seguridad .pdf'
    },
  { 
      id: 6, 
      name: 'Catálogo de equipo contra incendios', 
      section: 'Equipo contra incendios',
      image: 'images/products/CatalogoIncendios.jpeg', 
      description: 'CATALOGO EQUIPO CONTRA INCENDIOS', 
      pdf: 'images/products/Catalogo Equipo contra incendios .pdf'
    },
  { 
      id: 7, 
      name: 'Polvo', 
      section: 'Venta de polvo normado no certificado',
      image: 'images/products/Polvo.jpeg', 
      description: 'Normado no certificado', 
      
    },
  { 
      id: 8, 
      name: ' Chalecos de malla unitalla, con texto y con reflejante textil', 
      section: 'Equipo de protección personal',
      image: 'images/products/ChalecosMalla.jpeg', 
      description: 'Varios modelos', 
      
    },
  { 
      id: 9, 
      name: 'Chalecos de gabardina unitalla, sin texto, con bolsas ocultas', 
      section: 'Equipo de protección personal',
      image: 'images/products/ChalecosGabardinas.jpeg', 
      description: 'Varios modelos', 
      
    },
  { 
      id: 10, 
      name: 'Cascos de plástico con ajuste de intervalos', 
      section: 'Equipo de protección personal',
      image: 'images/products/Cascos de plástico con ajuste de intervalos.jpeg', 
      description: 'Varios modelos', 
      
    },
  { 
      id: 11, 
      name: 'Barbiquejo para casco', 
      section: 'Equipo de protección personal',
      image: 'images/products/Barbiquejo para casco.jpeg', 
      description: ' Barbiquejo con mentón para ajustar casco 2BAR-01', 
      
    },
  { 
      id: 12, 
      name: 'Gorras con texto bordado', 
      section: 'Equipo de protección personal',
      image: 'images/products/Gorras con texto bordado.jpeg', 
      description: 'Varios modelos', 
      
    },
  { 
      id: 13, 
      name: 'Brazaletes con texto y bies reflejante', 
      section: 'Equipo de protección personal',
      image: 'images/products/Brazaletes con texto y bies reflejante.jpeg', 
      description: 'Varios modelos', 
      
    },
  { 
      id: 14, 
      name: 'Camilla plegable de plástico', 
      section: 'Equipo de protección personal',
      image: 'images/products/Camilla plegable de plástico.jpeg', 
      description: ' camilla de rescate plegable pesa: 5.500 Kg soporta: 90 kg. 52 x 180 cm.', 
      
    },
  { 
      id: 15, 
      name: 'Inmovilizador', 
      section: 'Equipo de protección personal',
      image: 'images/products/Inmovilizador.jpeg', 
      description: 'Inmovilizador de cráneo', 
      
    },
  { 
      id: 16, 
      name: 'Silbato', 
      section: 'Equipo de protección personal',
      image: 'images/products/Silbato.jpeg', 
      description: ' Silbato de plástico con cordón', 
      
    },
  { 
      id: 17, 
      name: 'Kit de alarma de emergencia', 
      section: 'Equipo de protección personal',
      image: 'images/products/Kit de alarma de emergencia.jpeg', 
      description: 'sirena de activación manual, estrobo para sirena, eliminador para sirena y estrobo, botón económico alarma de emergencia ', 
      
    },
  { 
      id: 18, 
      name: 'Tapón auditivo reutilizable', 
      section: 'Equipo de protección personal',
      image: 'images/products/Tapón auditivo reutilizable.jpeg', 
      description: ' Tapón auditivo reutilizable, un par', 
      
    },
  { 
      id: 19, 
      name: 'Lentes de seguridad obscuros', 
      section: 'Equipo de protección personal',
      image: 'images/products/Lentes de seguridad obscuros.jpeg', 
      description: 'Lente de policarbonato tipo gota de mica gris obscura, patillas tipo espátula, puente nasal universal, diseño ergonómico, filtro UV, cuenta con tratamiento antirayaduras.', 
      
    },
  { 
      id: 20, 
      name: 'Zapatos borceguí omega dieléctrico', 
      section: 'Equipo de protección personal',
      image: 'images/products/Zapatos borceguí omega dieléctrico.jpeg', 
      description: 'Cuero de ganado vacuno acabado encerado, suela en color negro, inyección directa al corte, doble densidad, entresuela en PU, patín de fórmula tetrapolímeros y casquillo de policarbonato', 
      
    },
  { 
      id: 21, 
      name: 'Guantes de carnaza cortos', 
      section: 'Equipo de protección personal',
      image: 'images/products/Guantes de carnaza cortos.jpeg', 
      description: 'Con costuras en el dorso', 
      
    },
  { 
      id: 22, 
      name: 'Fajas', 
      section: 'Equipo de protección personal',
      image: 'images/products/Fajas.jpeg', 
      description: 'Varios modelos', 
      
    },
  { 
      id: 23, 
      name: 'Extintores completos vacíos', 
      section: 'Refacciones',
      image: 'images/products/Extintores completos vacíos.jpeg', 
      description: 'Incluye: cilindro, válvula 3/4” completa con tubo, manómetro de 1.2, chiflón, soporte abrazadera, etiquetas (instrucciones y récord)', 
      
    },
  { 
      id: 24, 
      name: 'Candado cola de rata', 
      section: 'Refacciones',
      image: 'images/products/Candado cola de rata.jpeg', 
      description: 'Candado estriado dennison cola rata 100 piezas', 
      
    },
  { 
      id: 25, 
      name: 'Extintor de agua completo vacío', 
      section: 'Refacciones',
      image: 'images/products/Extintor de agua completo vacío.jpeg', 
      description: 'Incluye; cilindro blanco, válvula 1” completa con tubo, manómetro de 1.2, manguera, soporte tipo L, etiquetas (instrucciones y récord)', 
      
    },
  { 
      id: 26, 
      name: 'Soporte para extintor', 
      section: 'Refacciones',
      image: 'images/products/Soporte para extintor.jpeg', 
      description: 'Soporte universal tipo “L”', 
      
    },
  { 
      id: 27, 
      name: 'Orrings', 
      section: 'Refacciones',
      image: 'images/products/Orrings.jpeg', 
      description: 'Varios modelos', 
      
    },
  { 
      id: 28, 
      name: 'Seguro para válvula', 
      section: 'Refacciones',
      image: 'images/products/Seguro para válvula.jpeg', 
      description: 'Varios modelos', 
      
    },
  { 
      id: 29, 
      name: 'Mangueras', 
      section: 'Refacciones',
      image: 'images/products/Mangueras.jpeg', 
      description: 'Varios modelos', 
      
    },
  { 
      id: 30, 
      name: 'Manómetros', 
      section: 'Refacciones',
      image: 'images/products/Manómetros.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 31, 
      name: 'Polvo químico seco', 
      section: 'Refacciones',
      image: 'images/products/Polvo químico seco.jpeg', 
      description: 'polvo químico seco azul sin certificar hesu 25 kg. polvo químico seco certificado azul pyrochem 25 kg.', 
      
    },
    { 
      id: 32, 
      name: 'Válvulas para extintor PQS', 
      section: 'Refacciones',
      image: 'images/products/Válvulas para extintor PQS.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 33, 
      name: 'Tubos Sifón', 
      section: 'Refacciones',
      image: 'images/products/Tubos Sifón.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 34, 
      name: 'Detector de monóxido y gas explosivo', 
      section: 'Equipo de seguridad',
      image: 'images/products/Detector de monóxido y gas explosivo.jpeg', 
      description: ' Alarma audible de 85 db. Detección de monóxido de carbono, gas propano y metano. Sensor electroquímico de CO. Display para lectura de niveles CO y gas. Batería de respaldo. Fácil instalación. Garantía de 5 años.', 
      
    },
    { 
      id: 35, 
      name: 'Detector de humo de batería', 
      section: 'Equipo de seguridad',
      image: 'images/products/Detector de humo de batería.jpeg', 
      description: 'Detector de humo con batería de corriente alterna', 
      
    },
    { 
      id: 36, 
      name: 'Gabinete cilíndrico en acero inoxidable', 
      section: 'Equipo de seguridad',
      image: 'images/products/Gabinete cilíndrico en acero inoxidable.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 37, 
      name: 'Gabinetes sin vidrio', 
      section: 'Equipo de seguridad',
      image: 'images/products/Gabinetes sin vidrio.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 38, 
      name: ' Equipo de respiración autónoma', 
      section: 'Equipo de seguridad',
      image: 'images/products/Equipo de respiración autónoma.jpeg', 
      description: 'Estructura ergonómica y antiestátitca, mangueras de presión alta y media, hebillas grandes y de fácil manejo, atalaje para botellas y funda de goma para manómetro. Duración de hasta 60 min.', 
      
    },
    { 
      id: 39, 
      name: ' Equipo de Bombero Brigadista', 
      section: 'Equipo de seguridad',
      image: 'images/products/Equipo de Bombero Brigadista.jpeg', 
      description: ' Incluye: Chaquetón, pantalón con tirantes, casco, botas, monja y guantes.', 
      
    },
    { 
      id: 40, 
      name: 'Zapapicos', 
      section: 'Equipo de seguridad',
      image: 'images/products/Zapapicos.jpeg', 
      description: 'Zapapicos de 5 LB. mango de fibra de vidrio y cabeza forjada con acero al carbón.', 
      
    },
    { 
      id: 41, 
      name: 'Palas', 
      section: 'Equipo de seguridad',
      image: 'images/products/Palas.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 42, 
      name: 'Hacha para bombero', 
      section: 'Equipo de seguridad',
      image: 'images/products/Hacha para bombero.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 43, 
      name: 'Botes areneros', 
      section: 'Equipo de seguridad',
      image: 'images/products/Botes areneros.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 44, 
      name: 'Impermeable gabardina corta', 
      section: 'Equipo de seguridad',
      image: 'images/products/Impermeable gabardina corta.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 45, 
      name: ' Botiquín particular con medicamento', 
      section: 'Equipo de seguridad',
      image: 'images/products/Botiquín particular con medicamento.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 46, 
      name: 'Lámpara de emergencia', 
      section: 'Equipo de seguridad',
      image: 'images/products/Lámpara de emergencia.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 47, 
      name: 'Gabinete para hidrante sin vidrio', 
      section: 'Equipo contra incendios',
      image: 'images/Gabinete para hidrante sin vidrio.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 48, 
      name: 'Manguera para hidrante', 
      section: 'Equipo contra incendios',
      image: 'images/Manguera para hidrante.jpeg', 
      description: '1 1/2” x 30 m. manguera para hidrante cuerda IPT', 
      
    },
    { 
      id: 49, 
      name: 'Extintores CO2 para fuegos de tipo B y C', 
      section: 'Equipo contra incendios',
      image: 'images/Extintores CO2 para fuegos de tipo B y C.jpeg', 
      description: 'Varios Modelos', 
      
    },
    { 
      id: 50, 
      name: 'Extintores con PQS certificado para fuegos tipo A, B y C', 
      section: 'Equipo contra incendios',
      image: 'images/Extintores con PQS certificado para fuegos tipo A, B y C.jpeg', 
      description: 'Varios Modelos', 
      
    },
    { 
      id: 51, 
      name: 'Extintor para fuegos de tipo K', 
      section: 'Equipo contra incendios',
      image: 'images/Extintor para fuegos de tipo K.jpeg', 
      description: '6 Lts. extintor para cocina Avenger ó Badger', 
      
    },
    { 
      id: 52, 
      name: 'Unidades móviles con PQS certificado para fuegos de tipo A, B y C', 
      section: 'Equipo contra incendios',
      image: 'images/Unidades móviles con PQS certificado para fuegos de tipo A, B y C.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 53, 
      name: 'Extintor de agua', 
      section: 'Equipo contra incendios',
      image: 'images/Extintor de agua.jpeg', 
      description: '9 Lts. extintor de acero al carbón', 
      
    },
    { 
      id: 54, 
      name: 'Fundas transparentes para extintor', 
      section: 'Equipo contra incendios',
      image: 'images/Fundas transparentes para extintor.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 55, 
      name: 'Granadas de PQS con rociador', 
      section: 'Equipo contra incendios',
      image: 'images/Granadas de PQS con rociador.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 56, 
      name: 'Toma siamesa', 
      section: 'Equipo contra incendios',
      image: 'images/Toma siamesa.jpeg', 
      description: '4” H NPT x 2 1/2” H NST', 
      
    },
    { 
      id: 57, 
      name: 'Señalamientos de Norma Armonizada', 
      section: 'Señalamientos',
      image: 'images/Norma Armonizada.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 58, 
      name: 'Señalamientos de Identificación de Sustancias Peligrosas', 
      section: 'Señalamientos',
      image: 'images/Identificación de Sustancias Peligrosas.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 59, 
      name: 'Señalamientos de Tuberías', 
      section: 'Señalamientos',
      image: 'images/Tuberías.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 60, 
      name: 'Señalamientos de Señalización Vial', 
      section: 'Señalamientos',
      image: 'images/Señalización Vial.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 61, 
      name: 'Señalamientos de Gasolinería', 
      section: 'Señalamientos',
      image: 'images/Gasolinería.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 62, 
      name: 'Señalamientos Sanitarios', 
      section: 'Señalamientos',
      image: 'images/Sanitarios.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 63, 
      name: 'Señalamientos de COFEPRIS AGEPSA CDMX', 
      section: 'Señalamientos',
      image: 'images/COFEPRIS AGEPSA CDMX.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 64, 
      name: 'Señalamientos de SEMARNAT', 
      section: 'Señalamientos',
      image: 'images/SEMARNAT.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 65, 
      name: 'Señalamientos de Ecología, Salud e Higiene', 
      section: 'Señalamientos',
      image: 'images/Ecología, Salud e Higiene.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 66, 
      name: 'Señalamientos de Precaución y Peligro', 
      section: 'Señalamientos',
      image: 'images/Precaución y Peligro.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 67, 
      name: 'Señalamientos de Obligación', 
      section: 'Señalamientos',
      image: 'images/Obligación.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 68, 
      name: 'Señalamientos de Prohibición', 
      section: 'Señalamientos',
      image: 'images/Prohibición.jpeg', 
      description: 'Varios modelos', 
      
    },
    { 
      id: 69, 
      name: 'Señalamientos de Información', 
      section: 'Señalamientos',
      image: 'images/Información.jpeg', 
      description: 'Varios modelos', 
      
    },
    // Agrega más productos si es necesario
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // El array vacío significa que solo se ejecuta cuando el componente se monta


  // Filtrar productos según el término de búsqueda y la sección seleccionada
  const filteredProducts = products.filter(product => 
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (selectedSection === 'Todos' || product.section === selectedSection)
  );

  // Obtener las secciones disponibles
  const sections = ['Todos', ...new Set(products.map(product => product.section))];


  const scrollMenu = (direction) => {
    if (sectionMenuRef.current) {
      const scrollAmount = sectionMenuRef.current.offsetWidth / 2;
      sectionMenuRef.current.scrollBy({ 
        left: direction === 'left' ? -scrollAmount : scrollAmount, 
        behavior: 'smooth' 
      });
    }
  };

  const handleProductClick = (product) => {
    if (product.pdf) {
      // Descargar el PDF si el producto tiene un archivo asociado
      window.open(product.pdf, '_blank');
    } else {
      // Redirigir a la página de contacto si no hay PDF
      const message = `Me intereso el artículo ${product.name} - ${product.description}`;
      navigate('/contact', { state: { prefilledMessage: message } });
    }
  };
  
  return (
    <div className="products-container">
      <h1>Catálogo de Productos</h1>
      {/* Leyenda fija en el lado izquierdo */}
      {!legendHidden && (
        <div 
          className="catalog-legend"
          onMouseEnter={() => setLegendHidden(true)}
          onClick={() => setLegendHidden(true)}
        >
          Para ver la variedad completa no dude en descargar el catálogo
        </div>
      )}
      {/* Submenú de Secciones */}
      <div className="section-menu-wrapper">
        {/*<button className="scroll-button left" onClick={() => scrollMenu('left')}>&lt;</button>*/}

      <div className="section-menu" ref={sectionMenuRef}>
        {sections.map(section => (
          <button 
            key={section} 
            className={`section-button ${section === selectedSection ? 'active' : ''}`}
            onClick={() => setSelectedSection(section)}
          >
            {section}
          </button>
        ))}
      </div>
      {/*<button className="scroll-button right" onClick={() => scrollMenu('right')}>&gt;</button>*/}
      </div>

      {/* Campo de búsqueda */}
      <input 
        type="text" 
        placeholder="Buscar productos..." 
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />

      {/* Lista de productos */}
      <div className="product-grid">
        {filteredProducts.map((product) => (
          <div 
            key={product.id} 
            className={`product-item ${hoveredProduct === product.id ? 'hover' : ''}`} 
            onMouseEnter={() => setHoveredProduct(product.id)}
            onMouseLeave={() => setHoveredProduct(null)}
            onClick={() => handleProductClick(product)} // Redirigir al hacer clic
          >
            <img src={product.image} alt={product.name} />
            <h2>{product.name}</h2>
            {hoveredProduct === product.id && (
              <div className="product-description">
                <p>{product.description}</p>
              </div>
            )}
          </div>
        ))}
      </div>
      {/* Botón de WhatsApp */}
      <WhatsAppButton /> 
    </div>
  );
  
};

export default Products;
