import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      {/* Iconos que se muestran cuando el ratón no está sobre el footer */}
      <div className="footer-icons">
        <div>
          <img src="/images/simbol3.jpg" alt="Contacto 1" />
          <img src="/images/simbol2.png" alt="Contacto 2" />
        </div>
        <div>
          <img src="/images/Facebook.png" alt="Facebook" />
          <img src="/images/X.jpg" alt="Twitter" />
          <img src="/images/Insta.png" alt="Instagram" />
        </div>
        <div>
          <img src="/images/simbol1.png"  alt="Dirección"/>
        </div>
      </div>

      {/* Contenido que se muestra cuando se pasa el ratón sobre el footer */}
      <div className="footer-content">
        <div className="footer-section footer-left">
          <h4>Dirección</h4>
          <p>Sta. Inés Mz. 58 Lot. 25 Viv 134 Paseo de los Ángeles, La trinidad
C.P.55614 Zumpango, Estado de México</p>
        </div>

        <div className="footer-section footer-center">
          <a href="https://www.facebook.com/profile.php?id=61563047822618&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
            <img src="/images/Facebook.png" alt="Facebook" />
          </a>
          <a href="https://x.com/InfoESIPI?t=lXem6kIuEytEqYIGuILXEg&s=09" target="_blank" rel="noopener noreferrer">
            <img src="/images/X.jpg" alt="Twitter" />
          </a>
          <a href="https://www.instagram.com/esipi_?igsh=aW51eGN4MzBleXk2" target="_blank" rel="noopener noreferrer">
            <img src="/images/Insta.png" alt="Instagram" />
          </a>
        </div>

        <div className="footer-section footer-right">
          <h4>Teléfonos</h4>
          <p>+52 1 55 2769 5519</p>
          <p>+52 1 221 194 8713</p>
          <p>+52 1 221 198 0195</p>
        </div>
      </div>

      <div className="copyright">
  &copy; 2024 Esipi. Todos los derechos reservados. 
  <a href="/documents/aviso_privacidad.pdf" download="Aviso_de_Privacidad">Aviso de privacidad</a>
</div>
    </footer>
  );
};

export default Footer;
