import React, { useState, useEffect } from 'react';
import './About.css';
import WhatsAppButton from './WhatsAppButton';

const About = () => {
  const [openPanel, setOpenPanel] = useState(null);

  const togglePanel = (panel) => {
    setOpenPanel(openPanel === panel ? null : panel);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-container">
      <div className="content-section">
        <h1>Quiénes Somos</h1>
        <p>Somos una empresa comprometida con la excelencia...</p>

        <div className="panels">
          <div className="panel" onClick={() => togglePanel('mision')}>
            <h2>Misión</h2>
            {openPanel === 'mision' && <p>Nuestra misión es...</p>}
          </div>
          <div className="panel" onClick={() => togglePanel('vision')}>
            <h2>Visión</h2>
            {openPanel === 'vision' && <p>Nuestra visión es...</p>}
          </div>
          <div className="panel" onClick={() => togglePanel('normas')}>
            <h2>Normas</h2>
            {openPanel === 'normas' && <p>Nuestras normas son...</p>}
          </div>
        </div>
      </div>
      <div className="logo-section2">
        <img src="images/Logo_B.jpeg" alt="Logo" className="logo" />
      </div>
      <WhatsAppButton /> 
    </div>
  );
};

export default About;
