import React, { useState } from 'react';
import './Services.css';
import WhatsAppButton from './WhatsAppButton';

const servicesData = [
  {
    title: "VENTA DE EQUIPO RECARGA Y MANTENIMIENTO DE EXTINTORES",
    content: [
      "Polvo químico seco",
      "C02",
      "Agua",
      "Clase K, Clase D, HFC 236",
      "Venta de extintores",
      "Venta de trajes de bombero",
      "Venta de gabinetes y porta extintores",
      "Venta e Instalación de Señalética de acuerdo a la NOM 026-STPS-2008",
      "Guantes",
      "Cascos",
      "SEGURIDAD INDUSTRIAL Y PROTECCIÓN DE INCENDIOS",
      "Chalecos",
      "Botas de Seguridad",
      "Arnés de seguridad",
      "Tambos contra incendios",
      "Detectores de humo",
      "Conos de transito",
      "Trafitambos",
      "Botiquín de primeros auxilios",
      "y más...",
    ],
    image: "/images/Services1.JPG",
  },
  {
    title: "PROTECCIÓN CIVIL",
    content: [
      "GESTION Y ASESORIA:",
      "Programa Interno de Protección Civil Estatal",
      "Programa Interno de Protección Civil Municipal, Artículo 103 de la Ley del Sistema Estatal de Protección Civil del Estado",
      "Dictamen estructural emitido por DRO autorizado",
      "Dictamen eléctrico (NOM-001-SEDE-2012). De acuerdo con los lineamientos para la integración de los Programas Internos de Protección Civil, emitido por la Secretaría de Gobernación del Estado",
      "Dictamen de Gas. Artículo 111 de la Ley del Sistema Estatal de Protección Civil del Estado",
      "Constancia de Bomberos. Artículo 47 de la Ley de ingresos del Estado",
      "Capacitación a Brigadas de emergencia NOM-002-STPS-2010 y Requisitos del Programa Interno de Protección Civil",
    ],
    image: "/images/Services2.JPG",
  },
  {
    title: "LICENCIAS Y PERMISOS EN PUEBLA",
    content: [
      "LICENCIA DE FUNCIONAMIENTO: De acuerdo con el Código Fiscal y Presupuestario así como Código Reglamentario que aplique en cada municipio",
      "OPINIÓN TÉCNICA DE RIESGO PARA INMUEBLES: Artículo 30 Fracción XVI; Artículo 125 Fracción I de la Ley del Sistema Estatal de Protección Civil",
      "OPINIÓN TÉCNICA DE RIESGO PARA ANUNCIO ESPECTACULAR Y LICENCIA DE USO: Artículo 30 Fracción XVI; Artículo 125 Fracción I de la Ley del Sistema Estatal de Protección Civil Ley de Atención y Prevención de la Contaminación Visual y Auditiva para el Estado; artículos 1, 3, 4, 5, 6, 7, 14, 19, 20, 22, 23, 24",
      "GESTIONES ANTE DESARROLLO URBANO:",
      "1. Inicio de obra",
      "2. Uso de suelo general",
      "3. Campo de tiro",
      "4. Terminación de obra",
      "5. Alineación y número oficial",
      "6. Uso de suelo específico",
    ],
    image: "/images/Services3.JPG",
  },
  {
    title: "NORMATIVIDAD",
    content: [
      "Evaluación de las Condiciones de seguridad. NOM-001-STPS-2008",
      "Estudio Riesgo de Incendio. NOM-002-STPS-2012",
      "Estudio Maquinaria y Equipo. NOM-004-STPS-1999",
      "Estudio Riesgo Sustancias Químicas. NOM-005-STPS-1998",
      "Procedimiento de Manejo de Materiales. NOM-006-STPS-2014",
      "Estudio Riesgo Trabajo Alturas. NOM-009-STPS-2011",
      "Estudio Agentes Contaminantes. NOM-010-STPS-2014",
      "Estudio de Ruido Laboral. NOM-011-STPS-2001",
      "Estudio de Condiciones Térmicas. NOM-015-STPS-1994",
      "Estudio de Uso y Manejo de Equipo de Protección Personal. NOM-017-STPS-2008",
      "Sistema Globalmente Armonizado. NOM-018-STPS-2015",
      "Asesoría para la integración de las Comisiones de Seguridad e Higiene. NOM-019-STPS-2011",
      "Revisión de la integración de las Comisiones de Seguridad e Higiene. NOM-019-STPS-2011",
      "Análisis de las condiciones de los Recipientes Sujetos a Presión. NOM-020-STPS-2011",
      "Estudio de Tierras Físicas. NOM-022-STPS-2015",
      "Estudio de Vibraciones. NOM-024-STPS-2001",
      "Estudio de Iluminación. NOM-025-STPS-2008",
      "Estudio Riesgo por Soldadura. NOM-027-STPS-2008",
      "Estudio Riesgo Mantenimiento a las Instalaciones Eléctricas. NOM-029-STPS-2011",
      "Diagnóstico de Seguridad y Salud en el Trabajo. NOM-030-STPS-2009",
      "Estudio Riesgo Espacios Confinados. NOM-033-STPS-2015",
      "Estudio Riesgo Psicosocial. NOM-035-STPS-2018",
      "Estudio Ergonomía. NOM-036-1-STPS-2018",
      "Capacitación de cada una de las NOM de la S. T. P. S.",
      "Consultoría para el Programa de Autogestión en Seguridad y Salud en el Trabajo",
      "Consultoría para la ISO-45000",
    ],
    image: "/images/Services4.JPG",
  },
  {
    title: "SISTEMAS CONTRA INCENDIOS",
    content: [
      "DISEÑO, INGENIERÍA, CÁLCULO HIDRÁULICO, SUMINISTRO, INSTALACIÓN Y MANTENIMIENTO DE SISTEMAS HIDRÁULICOS CONTRA INCENDIO:",
      "Hidrantes",
      "Alimentadores verticales (Riser)",
      "Rociadores",
      "Boquillas",
      "Sistemas de supresión de incendios",
      "Cuarto de Bombas",
      "Prueba de Eficiencia de la Bomba Contra Incendio acoplada a un motor combustión interna",
      "DISEÑO, SUMINISTRO, INSTALACIÓN Y MANTENIMIENTO DE SISTEMAS DE ALERTAMIENTO Y DETECCIÓN DE INCENDIOS:",
      "Puntual",
      "Aspiración temprana",
      "Photo Beam",
      "Diseño, Suministro, Instalación y Mantenimiento de Instalaciones Eléctricas",
      "Diagramas unifilares de instalaciones eléctricas en operación",
      "Planos de Sistemas Hidráulicos Contra Incendio instalados",
      "Plano de Protección Civil (ubicación de señalética y áreas con riesgo)",
    ],
    image: "/images/Services5.JPG",
  },
  {
    title: "AMBIENTAL",
    content: [
      "GESTIÓN Y ASESORIA:",
      "Manifiesto de Impacto Ambiental",
      "Informe preventivo de impacto ambiental",
      "Informe preventivo de impacto ambiental por operaciones",
      "Registro Generador Residuos Peligrosos",
      "Plan de manejo integral de Residuos Peligrosos Alta y Procedimiento de Manejo de Residuos de Manejo Especial",
      "Estudio de Ruido Perimetral. NOM-081-SEMARNAT-1994",
    ],
    image: "/images/Services6.JPG",
  },
];

const Services = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const togglePanel = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="services-container">
      <h1 className="services-title">Los servicios que ofrecemos son</h1>
      {servicesData.map((service, index) => (
        <div
          key={index}
          className={`service-panel ${openIndex === index ? "open" : ""}`}
          onClick={() => togglePanel(index)}
          style={{ backgroundImage: `url(${service.image})` }}
        >
          <div className="service-header">
            <h2>{service.title}</h2>
            <span className="toggle-arrow">{openIndex === index ? "▲" : "▼"}</span>
          </div>
          {openIndex === index && (
            <div className="service-content">
              {Array.isArray(service.content) ? (
                <ul>
                  {service.content.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              ) : (
                <p>{service.content}</p>
              )}
            </div>
          )}
        </div>
      ))}
      <WhatsAppButton /> 
    </div>
  );
};

export default Services
