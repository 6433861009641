import React from 'react';

const Appointment = () => {
  return (
    <div className="container mt-5">
      <h1>Book an Appointment</h1>
      <p>Form to book an appointment.</p>
    </div>
  );
};

export default Appointment;
