import React from 'react';
import './WhatsAppButton.css'; // Asegúrate de importar el archivo CSS para estilos

const WhatsAppButton = () => {
  return (
    <div className="whatsapp-float">
      <a
        href="https://wa.me/5212211948713?text=Quiero%20obtener%20información%20de:"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-whatsapp whatsapp-icon"></i>
      </a>
    </div>
  );
};

export default WhatsAppButton;
