// Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import './Header.css';

const Header = () => {
  return (
    <Navbar expand="lg" className="navbar-custom">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/" className="nav-link-custom"><span>Inicio</span></Nav.Link>
         {/* <Nav.Link as={Link} to="/about" className="nav-link-custom"><span>Sobre Nosotros</span></Nav.Link>*/}
          <Nav.Link as={Link} to="/products" className="nav-link-custom"><span>Productos</span></Nav.Link>
          <Nav.Link as={Link} to="/contact" className="nav-link-custom"><span>Contacto</span></Nav.Link>
        </Nav>
        <Navbar.Brand as={Link} to="/" className="navbar-logo">
          <img src="/images/Logo.png" alt="Logo" className="logo-image" />
        </Navbar.Brand>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
